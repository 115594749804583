import React, { useState, useEffect } from "react";
import axios from 'axios';
import env from "react-dotenv";
import { Pane, Heading, Paragraph, Dialog, Text, Checkbox, Badge, Button, IconButton, Strong, InfoSignIcon } from 'evergreen-ui';
import ExpandablePane from "./ExpandablePane";
import Download from "./Download";
import MetadataForm from "./MetadataForm";
import BookTabs from "./BookTabs";
import ReplacementsList from "./ReplacementsList";

const Book = (props) => {
  const { requestWithAuth } = props;
  const [expanded, setExpanded] = useState(false);
  const [isShown, setIsShown] = useState(false);
  const [metadata, setMetadata] = useState({
    title_long: "", 
    author_yomigana: "", 
    eisbn: "", 
    pisbn: "",
    ltr: false
  });

  const [changed, setChanged] = useState({});

  useEffect(() => {
    setMetadata({...metadata, ...props.data});
  }, [props.data]);

  const updateMetadata = (updated) => {
    let tmp = {};
    for (let k in updated) {
      if (updated[k] !== metadata[k]) {
        if (!changed.hasOwnProperty(k)) {
          tmp[k] = {old: metadata[k]};
        } else {
          tmp[k] = changed[k];
        }
        tmp[k]["new"] = updated[k];
      }
    }
    setMetadata({...metadata, ...updated});
    setChanged({...changed, ...tmp});
  }

  const updateMetadataInDatabase = async (book_id) => {
    const res = await requestWithAuth("post", `${env.BASE_URL}/api/books/${book_id}/update_book_metadata/`, metadata);
    // create a history item for the metadata change
    if (res && res.status === 200) {
      // action, book, user, detail
      let changes = [];
      for (let k in changed) {
        changes.push(changed[k]["old"] + " => " + changed[k]["new"]);  
      }
      let hist_res = handleAddHistory("metadata", changes.join(", "));
      // let history = {action: "metadata", book_id: book_id, user_id: props.user.user_id, detail: changes.join(", ")};
      // const hist_res = await axios.post(`${env.BASE_URL}/api/histories/none/new_history_item/`, history);
    }
  }

  const handleAddHistory = async (action, detail) => {
    let history = {action: action, book_id: props.data.id, user_id: props.user.user_id, detail: detail};
    const hist_res = await requestWithAuth("post", `${env.BASE_URL}/api/histories/none/new_history_item/`, history);
    return hist_res;
  }

  return (
    <Pane display="flex" flexDirection="row">
      <MultiSelect data={metadata} isbn={metadata.eisbn} selected={props.selected} handleSetDownloadSingle={props.handleSetDownloadSingle} requestWithAuth={requestWithAuth} />
      <ExpandablePane 
        expanded={false} 
        onSetExpanded={setExpanded}
        label={<LabelInner data={metadata} expanded={expanded} setIsShown={setIsShown} />} 
        content={<BookInner data={metadata} updateMetadataInDatabase={updateMetadataInDatabase} updateMetadata={updateMetadata} handleReload={props.handleReload} handleTriggerConversion={props.handleTriggerConversion} handleSetStatus={props.handleSetStatus} handleAddHistory={handleAddHistory} user={props.user} currentUser={props.currentUser} requestWithAuth={requestWithAuth} />} 
      />
      <Pane>
        <Dialog
          isShown={isShown}
          title="About Conversion Failures"
          onCloseComplete={() => setIsShown(false)}
          confirmLabel="Got it!"
          hasCancel={false}
        >
          There are many reasons why a conversion could fail: 
          It's too large, or there's something in the file 
          that the converter can't handle, or the converter 
          encountered a bug. To troubleshoot the issue, 
          contact jude@kodanshausa.com and include the 
          book isbn, date and time of your conversion 
          attempt (you can get this from the "Last conversion:"
          section below), and ideally the original files you 
          attempted to convert.
        </Dialog>
      </Pane>
    </Pane>
  )
}

const MultiSelect = (props) => {
  const { requestWithAuth } = props;
  let epub_download = props.data.conversions && props.data.conversions.length > 0 && props.data.conversions[0].epub_download;

  let pdf_download = props.data.conversions && props.data.conversions.length > 0 && props.data.conversions[0].pdf_download;

  let cover_download = props.data.conversions && props.data.conversions.length > 0 && props.data.conversions[0].cover_download;

  return (
    <Pane display="flex" flexDirection="row" width={116} justifyContent="space-between">
      <Checkbox 
        marginLeft={12} 
        marginRight={8} 
        disabled={!epub_download} 
        checked={props.selected.hasOwnProperty(props.isbn) && props.selected[props.isbn].epub}
        onChange={e => {
          props.handleSetDownloadSingle(props.isbn, "epub");
        }}
      />
      <Checkbox 
        marginRight={8} 
        disabled={!pdf_download} 
        checked={props.selected.hasOwnProperty(props.isbn) && props.selected[props.isbn].pdf} 
        onChange={e => {
          props.handleSetDownloadSingle(props.isbn, "pdf");
        }}
      />
      <Checkbox 
        marginRight={8} 
        disabled={!cover_download} 
        checked={props.selected.hasOwnProperty(props.isbn) && props.selected[props.isbn].cover} 
        onChange={e => {
          props.handleSetDownloadSingle(props.isbn, "cover");
        }}
      />
    </Pane>
  )
}

const ConversionBadge = (props) => {

  let color = props.status === "SUCCESS"
    ? "green"
    : props.status === "FAIL"
    ? "red"
    : "yellow";

  let created = props.created.replace(/T\d.*/, "");

  return (
    <Badge color={color}>
      {props.status}{` `}
      {created}
    </Badge>
  )
}

const FailInfo = (props) => {
  return (
    <IconButton icon={InfoSignIcon} intent="danger" appearance="minimal" onClick={e => props.setIsShown(true)} />
  )
}

const LabelInner = (props) => {
  const [title, setTitle] = useState("");

  useEffect(() => {
    if (props.data.title_long && props.data.title_long.length > 50 && props.expanded === false) {
      setTitle(props.data.title_long.slice(0, 50) + "...");
    } else {
      setTitle(props.data.title_long);
    }
  }, [props.data, props.data.title_long, props.expanded]);

  return (
    <Text display="flex" flexDirection="row" justifyContent="space-between" width="740px" lineHeight="48px">
      <Text maxWidth="440px" lineHeight={!props.expanded ? "48px" : "16px"} paddingTop={!props.expanded ? "0px" : "16px"}>{title}</Text>
      <Text>
        {props.data.conversions && props.data.conversions.length > 0 && (
          <ConversionBadge status={props.data.conversions[0].status} created={props.data.conversions[0].created} />
        )}
        {props.data.conversions && props.data.conversions.length > 0 && props.data.conversions[0].status === "FAIL" && (
          <FailInfo setIsShown={props.setIsShown} />
        )}
        <Text lineHeight="48px" marginLeft={12}>{props.data && props.data.eisbn ? props.data.eisbn : ""}</Text>
      </Text>
    </Text>
  )
}

const BookInner = (props) => {
  const { requestWithAuth } = props;
  const [showEdit, setShowEdit] = useState(false);
  const [original, setOriginal] = useState({});
  const [shouldSetOriginal, setShouldSetOriginal] = useState(true);

  useEffect(() => {
    if (props.data.eisbn && shouldSetOriginal) {
      setOriginal(props.data);
      setShouldSetOriginal(false);
    }
  }, [props.data]);

  const handleDelete = async () => {
    const res = await requestWithAuth("post", `${env.BASE_URL}/api/books/${props.data.id}/delete_book/`, {eisbn: props.data.eisbn});
    if (res && res.data) {
      // reload the list of books
      props.handleReload();
    }
  }

  return (
    <Pane 
      paddingLeft={32}
      paddingRight={28}
      paddingBottom={16}
    >
      <Pane display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
        <Paragraph width="500px">Author(s): {props.data.author_yomigana}</Paragraph>
        <Paragraph width="200px" textAlign="right">{props.data.pisbn}</Paragraph>
      </Pane>
      <Pane display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
        <Checkbox label="Reads LTR" checked={props.data.ltr} />
        <Button onClick={e => setShowEdit(true)}>Edit Metadata</Button>
      </Pane>
      <Pane >
        <Dialog
          isShown={showEdit}
          title="Edit Book Metadata"
          onCloseComplete={() => setShowEdit(false)}
          confirmLabel="Save"
          onCancel={(close) => {
            props.updateMetadata(original);
            close();
          }}
          onConfirm={(close) => {
            props.updateMetadataInDatabase(props.data.id);
            close();
          }}
        >
          <MetadataForm updateMetadata={props.updateMetadata} metadata={props.data} />
        </Dialog>
      </Pane>
      {props.data.conversions && props.data.conversions.length > 0 && (
        <Conversion data={props.data.conversions[0]} book_id={props.data.id} user_id={props.user.user_id} handleAddHistory={props.handleAddHistory} requestWithAuth={requestWithAuth} />
      )}
      <BookTabs metadata={props.data} handleTriggerConversion={props.handleTriggerConversion} handleSetStatus={props.handleSetStatus} handleAddHistory={props.handleAddHistory} requestWithAuth={requestWithAuth} />
      {props.currentUser && props.currentUser.role === "admin" && (
        <DeleteBook handleDelete={handleDelete} handleReload={props.handleReload} eisbn={props.data.eisbn} />
      )}
    </Pane>
  )
}

const Conversion = (props) => {
  const { requestWithAuth } = props;
  const [mode, setMode] = useState("local");
  const [showdownloads, setShowdownloads] = useState(false);
  const [downloading, setDownloading] = useState(false);

  let opts = [];

  for (let k in props.data.options) {
    if (props.data.options[k] === true) {
      opts.push(k);
    }
  }

  let canDownload = props.data.epub_download || props.data.pdf_download || props.data.cover_download;

  let downloads = [
    props.data.epub_download, 
    props.data.pdf_download, 
    props.data.cover_download, 
    props.data.log_download
  ];

  return (
    <Pane 
      paddingLeft={32}
      paddingRight={28}
      paddingBottom={16}
      paddingTop={12}
      marginLeft="-32px"
      marginRight="-28px"
      background="tint1"
      display="flex" 
      flexDirection="row" 
      justifyContent="space-between" 
      alignItems="flex-start"
    >
      <Pane width="350px">
        <Paragraph size={300}><Strong size={300}>Last conversion:</Strong></Paragraph>
        <Paragraph size={300}>{props.data.created} by {props.data.user}</Paragraph>
        <Paragraph size={300}>Notes: {props.data.notes}</Paragraph>
        {canDownload && (
          <Pane>
            <Paragraph marginTop={8}>
              <Button 
                disabled={downloading} 
                onClick={e => {
                  setMode("dropbox");
                  setShowdownloads(true);
                }}
              >
                {downloading ? "Saving..." : "Save to Dropbox..."}
              </Button>
            </Paragraph>
            <Paragraph marginTop={8}>
              <Button 
                disabled={downloading} 
                onClick={e => {
                  setMode("local");
                  setShowdownloads(true);
                }}
              >
                {downloading ? "Downloading..." : "Download to my computer..."}
              </Button>
            </Paragraph>
            <Download 
              show={showdownloads} 
              handleSetShown={setShowdownloads} 
              handleSetDownloading={setDownloading} 
              handleAddHistory={props.handleAddHistory}
              files={downloads} 
              mode={mode} 
              book_id={props.book_id}
              user_id={props.user_id}
              requestWithAuth={requestWithAuth}
            />
          </Pane>
        )}
      </Pane>
      <Pane width="350px">
        <Paragraph size={300}>Conversion details:</Paragraph>
        <Paragraph size={300}>Interior PDF: {props.data.interior}</Paragraph>
        <Paragraph size={300}>Cover File: {props.data.cover ? props.data.cover : "NONE"}</Paragraph>
        <Paragraph size={300}>Copyright File: {props.data.copyright ? props.data.copyright : "NONE"}</Paragraph>
        <Pane size={300} display="flex" flexDirection="row">
          <Paragraph size={300}>Replacements: </Paragraph>
          {props.data.replacements ? <ReplacementsList replacements={props.data.replacements} /> : <Paragraph size={300}>NONE</Paragraph>}
        </Pane>
        <Paragraph size={300}>Export Options: {opts.length > 0 ? opts.join(", ") : "NONE"}</Paragraph>
      </Pane>
    </Pane>
  )
}

const DeleteBook = (props) => {
  const [show, setShow] = useState(false);

  return (
    <Pane 
      display="flex" 
      flexDirection="row" 
      justifyContent="space-between" 
      alignItems="center"
      background="tint1"
      borderTop="default"
      marginRight="-28px"
      marginLeft="-32px"
      marginBottom="-16px"
      paddingTop={16}
      paddingRight={28}
      paddingBottom={16}
      paddingLeft={32}
    >
      <Paragraph color="red500"><Strong color="red500">Danger Zone:</Strong></Paragraph>
      <Button 
        appearance="primary" 
        intent="danger"
        onClick={e => setShow(!show)}
      >
        Delete Book...
      </Button>
      <Dialog
        isShown={show}
        title={`Delete Book (${props.eisbn})`}
        hasCancel={true}
        onCloseComplete={() => setShow(false)}
        confirmLabel="Delete"
        onConfirm={(close) => {
          props.handleDelete();
          close();
        }}
      >
        <Paragraph size={300} marginTop={12} fontStyle="italic">
          Clicking "Delete" will delete the book record in this database as well as any associated
          {` `} conversion data and files in AWS S3. 
          {` `}Files you've downloaded locally or to Dropbox will NOT be affected.
        </Paragraph>
        <Paragraph size={300} marginTop={12} fontStyle="italic">
          Click "Delete" to proceed.
        </Paragraph>
      </Dialog>
    </Pane>
  );
}

export default Book;
