import React, { useContext, useState, useEffect } from 'react';
import axios from "axios";
import { Pane, Paragraph, CogIcon } from 'evergreen-ui';

import AuthContext from '../context/AuthContext';
import StatusContext from '../context/StatusContext';
import NotificationsBar from "../components/NotificationsBar";
import MainHeader from "../components/MainHeader";
import MainTabs from "../components/MainTabs";
import MainContent from "../components/MainContent";
import Status from "../components/Status";
import env from "react-dotenv";

const HomePage = (props) => {
  const { user, email, authTokens, logoutUser } = useContext(AuthContext);
  const [un, setUn] = useState(() => (localStorage.getItem('kodUN') ? JSON.parse(localStorage.getItem('kodUN')) : null));
  const { 
    status, 
    handleSetStatus, 
    handleClearStatus, 
    handleSetUpdate, 
    updateStatusItemByEisbn, 
    updateStatusItem 
  } = useContext(StatusContext);

  const [credentials, setCredentials] = useState(true);
  const [selectedTab, setSelectedTab] = useState("uploadtab");
  const [updated, setUpdated] = useState([]);
  const [currentUser, setCurrentUser] = useState(null);
  const [query, setQuery] = useState("");
  const [load, setLoad] = useState(true);
  const [error, setError] = useState(null);

  const requestWithAuth = async (requestType, url, data={}) => {
    try {
      if (credentials) {
        let localAuthTokens = JSON.parse(localStorage.getItem('authTokens'));
        // let authToken = localAuthTokens.hasOwnProperty("access") ? localAuthTokens.access : localAuthTokens.refresh;
        let authToken = localAuthTokens.access;
        // const config = {headers: {Authorization: `Bearer ${credentials.hasOwnProperty("access") ? credentials.access : credentials.refresh}`}};
        const config = {headers: {Authorization: `Bearer ${authToken}`}};
        if (requestType === "put") {
          const res = await axios.put(url, data, config);
          return await res;
        } else if (requestType === "get") {
          const res = await axios.get(url, config);
          return await res;
        } else {
          const res = await axios.post(url, data, config);
          return await res;
        }
      }
    } catch (error) {
      if (error.response.status === 401) {
        logoutUser();
      } else {
        setError(error.message);
      }
    }
  }

  const onSelectTab = (tab) => {
    setError(null);
    setSelectedTab(tab);
  }

  const fetch_conversion = async (conversion_id) => {
    const res = await requestWithAuth("get", `${env.BASE_URL}/api/conversions/${conversion_id}/`);
    return await res;
  }

  const set_conversion_status = async (conversion, status) => {
    let conversionId = conversion.data.id;
    let newData = conversion.data;
    newData.status = status;
    delete newData.id;
    const res = await requestWithAuth("put", `${env.BASE_URL}/api/conversions/${conversionId}/`, newData);
  }

  const handleSearch = (query, tab="searchtab") => {
    setQuery(query);
    if (tab === "searchtab") {
      setSelectedTab("searchtab");
    }
  }

  const handleResetSearch = () => {
    setQuery("");
  }

  const handleLoadBooks = (tmpload) => {
    setLoad(tmpload);
  }

  // poll for updates, every 2 minutes a max of 11 times (22 minutes total);
  // set conversion status to FAIL if no update found after 22 minutes (max lambda timeout is 15min).
  useEffect(() => {
    if (status.length > 0) {
      const checkForStatusUpdates = async () => {
        for (let i=0; i<status.length; i++) {
          let item = status[i];
          if (item.status === "IN_PROGRESS") {
            // check the DB for any changes
            let conversion = await fetch_conversion(item.conversion_id);
            // if status has changed, update it in state
            if (item.hasOwnProperty("poll_count") && item["poll_count"]+1 > 11) {
              console.log("nothing to be done");
            } else if (conversion.data.status !== item.status) {
              let message = "Converting...";
              if (conversion.data.status === "SUCCESS") {
                message = "SUCCESS!";
              } else if (conversion.data.status === "FAIL") {
                message = "Conversion failed :(";
              }
              let [revised_arr, updated_item] = updateStatusItem(status, item, i, {"status": conversion.data.status, "poll_count": 12, "message": message});
              handleSetStatus(status => revised_arr);
            } else if (item.hasOwnProperty("poll_count") && item["poll_count"]+1 === 11) {
              let poll_count = item.hasOwnProperty("poll_count") ? item["poll_count"]+1 : 1;
              let [revised_arr, updated_item] = updateStatusItem(status, item, i, {"status": "FAIL", "poll_count": poll_count, "message": "Conversion failed :("});
              handleSetStatus(status => revised_arr);
              setUpdated(updated => [...updated, updated_item]);
              set_conversion_status(conversion, "FAIL");
            } else {
              let poll_count = item.hasOwnProperty("poll_count") ? item["poll_count"]+1 : 1;
              let [revised_arr, updated_item] = updateStatusItem(status, item, i, {"poll_count": poll_count});
              handleSetStatus(status => revised_arr);
            }
          }
        }
      }

      // for testing: const intervalID = setInterval(checkForStatusUpdates, 30000, status);
      const intervalID = setInterval(checkForStatusUpdates, 120000, status);
    }
  }, [status]);

  useEffect(() => {
    setCredentials(authTokens);
  }, [authTokens]);

  useEffect(() => {
    if (user.user_id && !currentUser) {
      const getCurrentUser = async (user_id) => {
        const res = await requestWithAuth("post", `${env.BASE_URL}/api/user_roles/none/get_current_user_role/`, {user_id: user.user_id});
        if (res && res.data) {
          setCurrentUser({"role": res.data.role});
        }
      }
      getCurrentUser(user.user_id);
    }
  }, [user]);

  return (
    user ? (
    <Pane height="100vh">
      <MainHeader requestWithAuth={requestWithAuth} handleSearch={handleSearch} handleLoadBooks={handleLoadBooks} />
      {error && (
        <NotificationsBar error={error} />
      )}
      <Pane display="flex" flexDirection="row">
        <Pane 
          display="flex" 
          flexDirection="column" 
          alignItems="flex-start" 
          justifyContent="space-between" 
          width="300px"
          height="calc(100vh - 64px)"
          border="default"
        >
          <Pane display="flex" alignItems="flex-start" justifyContent="flex-start" width="300px" flexDirection="column">
            <MainTabs selectedTab={selectedTab} onSelectTab={onSelectTab} requestWithAuth={requestWithAuth} />
            <Status items={status} handleClearStatus={handleClearStatus} requestWithAuth={requestWithAuth} />
          </Pane>
          <Pane display="flex" flexDirection="row" alignItems="center" padding={12} width="300px">
            <CogIcon />
            {currentUser && (
              <Paragraph marginLeft={8} color="blue500" onClick={e => setSelectedTab("profiletab")}>
                Signed in as: {un ? un : email} {currentUser.role === "admin" && "(Admin)"}
              </Paragraph>
            )}
          </Pane>
        </Pane>
        <MainContent 
          query={query} 
          selectedTab={selectedTab} 
          load={load}
          handleLoadBooks={handleLoadBooks}
          handleSetStatus={handleSetStatus} 
          updateStatusItemByEisbn={updateStatusItemByEisbn}
          handleSearch={handleSearch}
          handleResetSearch={handleResetSearch} 
          user={user} 
          currentUser={currentUser} 
          requestWithAuth={requestWithAuth} 
        />
      </Pane>
    </Pane>
    ) : (
    <Pane>
      <Paragraph>You are not logged in - redirecting...</Paragraph>
    </Pane>
    )
  )
}

export default HomePage;