import React, { useContext, useState, useEffect } from "react";
import axios from "axios";
import https from "https-browserify";
import { Pane, Heading, Paragraph, Button } from 'evergreen-ui';
import getFormattedTimestr from "../utils/helpers";
import StatusContext from '../context/StatusContext';
import BatchUpload from "./BatchUpload";
import ManualUpload from "./ManualUpload";
import EditEpub from "./EditEpub";
import Books from "./Books";
import Profile from "./Profile";
import SearchOptions from "./SearchOptions";
import ReportOptions from "./ReportOptions";
import env from "react-dotenv";

const MainContent = (props) => {
  const { status, handleSetStatus, handleSetUpdate } = useContext(StatusContext);
  const { requestWithAuth } = props;
  const [searchCount, setSearchCount] = useState(0);
  const [convertQueue, setConvertQueue] = useState({});

  const handleSetSearchCount = (count) => {
    setSearchCount(count);
  }

  const handleSetConvertQueue = (arr) => {
    setConvertQueue(prevConvertQueue => ({...prevConvertQueue, [arr[0].eisbn]: arr}));
  }

  const handleSetConvertQueueBatch = (arr) => {
    setConvertQueue(prevConvertQueue => {
      let newConvertQueue = {...prevConvertQueue};
      for (let i=0; i<arr.length; i++) {
        newConvertQueue[arr[i][0].eisbn] = arr[i];
      }
      return newConvertQueue;
    });
  }

  const triggerConvert = async (item) => {
    const res = await requestWithAuth("post", `${env.BASE_URL}/api/books/none/trigger_convert/`, {
      item,
      user_id: props.user.user_id,
    });
    return await res;
  };

  const put = async (url, data) => {
    const response = await fetch(url, { method: "PUT", body: data });
    const res = await response.ok;
    return res;
  };

  const readFileAndUpload = async (file, key, item) => {
    if (!file) return;
    
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = async () => {
        try {
          const binaryStr = reader.result;
          const { data } = await requestWithAuth("props", `${env.BASE_URL}/api/books/none/get_presigned_url/`, {
            key,
            method: "put_object",
          });
          
          const success = await put(data.url, Buffer.from(binaryStr));
          if (success) {
            resolve(success);
          }
        } catch (error) {
          console.error("Upload error:", error);
        }
      };
      reader.readAsArrayBuffer(file);
    });
  };

  const countExpectedUploads = (item) => {
    let count = ["cover", "copyright", "interior"].filter((key) => item[key]).length;
    count += item.replacements ? item.replacements.length : 0;
    return count;
  };

  const triggerUpload = async (item) => {
    const uploadTasks = [];
    if (item.cover) uploadTasks.push([item.cover_file, item.cover_key, item]);
    if (item.copyright) uploadTasks.push([item.copyright_file, item.copyright_key, item]);
    if (item.interior) uploadTasks.push([item.interior_file, item.interior_key, item]);
    if (item.replacements) {
      item.replacements.forEach(({ file, key }) => {
        uploadTasks.push([file[0], key, item]);
      });
    }

    // Wait for all promises to resolve
    const results = await Promise.all(uploadTasks.map(async (arr) => {
      // Perform asynchronous operation for each item
      const response = await readFileAndUpload(arr[0], arr[1], arr[2]);
      return response;
    }));
    return results;
  }

  const createKeys = (item, note, conversionSettings, tmpConvId) => {
    // create the timestr
    const timestr = getFormattedTimestr(); // "%Y%m%d-%H%M%S"
    item["timestr"] = timestr;
    if (note && note.match(/\S/)) {
      item["notes"] = note;
    }
    item["options"] = conversionSettings;
    // set final filenames
    if (item.hasOwnProperty("cover")) {
      // upload cover to s3
      let cover_ext = item["cover"].match(/\.[^\/]+$/);
      let filename = item["eisbn"]+"_COVER"+cover_ext;
      let key = "INPUT-" + timestr + "/" + filename;
      let file = item["cover_file"];
      item["cover_key"] = key;
    }
    if (item.hasOwnProperty("copyright")) {
      // upload copyright to s3
      let filename = item["eisbn"]+"_COPYRIGHT.pdf";
      let key = "INPUT-" + timestr + "/" + filename;
      let file = item["copyright_file"];
      item["copyright_key"] = key;
    }
    // Upload replacement pages
    if (item.hasOwnProperty("replacements")) {
      for (let i=0; i<item.replacements.length; i++) {
        let r = item.replacements[i];
        let filename = "replacement-"+r.page+".pdf";
        let key = "INPUT-" + timestr + "/" + filename;
        r["key"] = key;
      }
    }
    if (item.hasOwnProperty("interior")) {
      // upload interior to s3
      let ext = ".pdf";
      if (item["interior_file"].path.indexOf(".epub") > -1) {
        console.log("is epub");
        ext = ".epub";
      }
      let filename = item["eisbn"]+ext;
      let key = "INPUT-" + timestr + "/" + filename;
      let file = item["interior_file"];
      item["interior_key"] = key;
      console.log(item);
    }
    return item;
  }

  const allBooksUploaded = () => {
    let uploading = status.filter(item => item.message === "Uploading files...");
    console.log(uploading);
    console.log(uploading.length === 0);
    return (uploading.length === 0);
  }

  useEffect(() => {
    async function handleTriggerUpload() {
      let processing = Object.keys(convertQueue);
      if (processing.length > 0) {
        processing.map(async (key, index) => {
          let arr = convertQueue[key];
          let updatedItem = createKeys(arr[0], arr[1], arr[2], arr[3]);
          await triggerUpload(updatedItem).then(async (res) => {
            await triggerConvert(updatedItem).then((res) => {
              if (res?.data?.message) {
                handleSetUpdate({ eisbn: updatedItem.eisbn, updates: { status: "FAIL", message: "File(s) failed to upload to S3" } });
              } else {
                handleSetUpdate({ eisbn: updatedItem.eisbn, updates: { conversion_id: res.data.id, status: "IN_PROGRESS", message: "Converting..." } });
              }
              if (index === processing.length-1) {
                setConvertQueue(prevConvertQueue => {
                  let newConvertQueue = {...prevConvertQueue};
                  for (let i=0; i<processing.length; i++) {
                    delete newConvertQueue[processing[i]];
                  }
                  return newConvertQueue;
                });
              }
            });
          });
        });
      }
    }
    handleTriggerUpload();
  }, [convertQueue]);

  const tabContentMap = {
    "uploadtab": <UploadContent handleTriggerConversion={handleSetConvertQueue} handleSetConvertQueueBatch={handleSetConvertQueueBatch} handleSetStatus={props.handleSetStatus} allBooksUploaded={allBooksUploaded} requestWithAuth={requestWithAuth} />,
    "bookstab": <BooksContent load={props.load} handleLoadBooks={props.handleLoadBooks} query={props.query} handleResetSearch={props.handleResetSearch} handleSetSearchCount={handleSetSearchCount} handleTriggerConversion={handleSetConvertQueue} handleSetStatus={props.handleSetStatus} user={props.user} currentUser={props.currentUser} requestWithAuth={requestWithAuth} />,
    "reportstab": <ReportContent load={props.load} handleLoadBooks={props.handleLoadBooks} query={props.query} searchCount={searchCount} handleSearch={props.handleSearch} handleResetSearch={props.handleResetSearch} handleSetSearchCount={handleSetSearchCount} handleTriggerConversion={handleSetConvertQueue} handleSetStatus={props.handleSetStatus} user={props.user} currentUser={props.currentUser} requestWithAuth={requestWithAuth} />,
    "searchtab": <SearchContent load={props.load} handleLoadBooks={props.handleLoadBooks} query={props.query} searchCount={searchCount} handleSearch={props.handleSearch} handleResetSearch={props.handleResetSearch} handleSetSearchCount={handleSetSearchCount} handleTriggerConversion={handleSetConvertQueue} handleSetStatus={props.handleSetStatus} user={props.user} currentUser={props.currentUser} requestWithAuth={requestWithAuth} />,
    "profiletab": <ProfileContent user={props.user} role={props.currentUser ? props.currentUser.role : "user"} requestWithAuth={requestWithAuth} />
  }

  return (
    <Pane 
      display="flex" 
      flexDirection="column" 
      alignItems="center" 
      justifyContent="flex-start" 
      padding={12}
      height="calc(100vh - 64px)"
      overflowY="scroll"
    >
      {tabContentMap[props.selectedTab]}
    </Pane>
  );
};

// ignore this -- resetting highlighting

const UploadContent = (props) => {
  const { requestWithAuth } = props;

  return (
    <Pane>
      <BatchUpload handleSetStatus={props.handleSetStatus} handleTriggerConversion={props.handleSetConvertQueueBatch} allBooksUploaded={props.allBooksUploaded} requestWithAuth={requestWithAuth} />
      <ManualUpload handleSetStatus={props.handleSetStatus} handleTriggerConversion={props.handleTriggerConversion} allBooksUploaded={props.allBooksUploaded} requestWithAuth={requestWithAuth} />
      <EditEpub handleSetStatus={props.handleSetStatus} handleTriggerConversion={props.handleTriggerConversion} requestWithAuth={requestWithAuth} />
    </Pane>
  );
}

const BooksContent = (props) => {
  const { requestWithAuth } = props;

  return (
    <Books 
      load={props.load}
      handleLoadBooks={props.handleLoadBooks}
      query={""} 
      handleResetSearch={props.handleResetSearch} 
      handleSetSearchCount={props.handleSetSearchCount} 
      handleTriggerConversion={props.handleTriggerConversion} 
      handleSetStatus={props.handleSetStatus} 
      user={props.user} 
      currentUser={props.currentUser}
      requestWithAuth={requestWithAuth} 
    />
  );
}

const ReportContent = (props) => {
  const { requestWithAuth } = props;
  const [advanced, setAdvanced] = useState(false);

  return (
    <Pane>
      <Heading marginLeft={16}>Create a Report</Heading>
      <ReportOptions handleLoadBooks={props.handleLoadBooks} handleSearch={props.handleSearch} />
      <Books 
        load={props.load}
        mode="report"
        handleLoadBooks={props.handleLoadBooks}
        query={props.query} 
        handleResetSearch={props.handleResetSearch} 
        handleSetSearchCount={props.handleSetSearchCount} 
        handleTriggerConversion={props.handleTriggerConversion} 
        handleSetStatus={props.handleSetStatus} 
        user={props.user} 
        currentUser={props.currentUser}
        requestWithAuth={requestWithAuth} 
      />
    </Pane>
  );
}

const SearchContent = (props) => {
  const { requestWithAuth } = props;
  const [advanced, setAdvanced] = useState(false);

  return (
    <Pane>
      <Heading marginLeft={16}>Search Results ({props.searchCount})</Heading>
      <Paragraph 
        size={300} 
        color="blue500"
        marginTop={8}
        marginLeft={16}
        onClick={e => setAdvanced(!advanced)}
      >
        {advanced ? "- Hide" : "+ Show"} advanced search options
      </Paragraph>
      {advanced && (
        <SearchOptions handleLoadBooks={props.handleLoadBooks} handleSearch={props.handleSearch} />
      )}
      <Pane marginTop={8} marginLeft={16}>
        <Button 
          onClick={e => {
            props.handleResetSearch();
            props.handleLoadBooks(true);
          }}>
            Clear Search
          </Button>
      </Pane>
      <Books 
        load={props.load}
        mode="report"
        handleLoadBooks={props.handleLoadBooks}
        query={props.query} 
        handleResetSearch={props.handleResetSearch} 
        handleSetSearchCount={props.handleSetSearchCount} 
        handleTriggerConversion={props.handleTriggerConversion} 
        handleSetStatus={props.handleSetStatus} 
        user={props.user} 
        currentUser={props.currentUser}
        requestWithAuth={requestWithAuth} 
      />
    </Pane>
  );
}

const ProfileContent = (props) => {
  const { requestWithAuth } = props;

  return (
    <Profile user={props.user} role={props.role} requestWithAuth={requestWithAuth} />
  );
}

export default MainContent;