import React, { useState, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import * as xlsx from 'xlsx/xlsx.mjs';
import { Pane, Paragraph, Dialog, Button, IconButton, CrossIcon } from 'evergreen-ui';

const DropFile = (props) => {
	const [file, setFile] = useState(null);
  const [warningMessage, setWarningMessage] = useState('');
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (file) {
      handleConvertExcel();
    }
  }, [file]);

  const onDrop = async (acceptedFiles, rejectedFiles, event) => {
  	if (props.type === "xlsx") {
	    let acceptedTypes = [
	      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
	    ];
	    if (acceptedTypes.indexOf(acceptedFiles[0].type) < 0) {
	      setWarningMessage('Please upload a .xlsx file');
	      return;
	    }
	    setFile(acceptedFiles[0]);
	    setWarningMessage('');
	    setErrors('');
    } else if (props.type === "pdf") {
      const files = [];
      // Iterate through dropped items
      for (let i = 0; i < acceptedFiles.length; i++) {
      	let file = acceptedFiles[i];
        if (file.type === 'application/pdf') {
        	files.push(file);
        }
      }
      props.handleFileData(files);
      setWarningMessage('');
	    setErrors('');
    } else if (props.type === "epub") {
      const files = [];
      // Iterate through dropped items
      for (let i = 0; i < acceptedFiles.length; i++) {
      	let file = acceptedFiles[i];
        if (file.type === 'application/epub+zip') {
        	files.push(file);
        }
      }
      props.handleFileData(files);
      setWarningMessage('');
	    setErrors('');
    } else if (props.type === "cover") {
      const files = [];
      // Iterate through dropped items
      let allowed = ['application/pdf', 'image/jpeg', 'image/png'];
      for (let i = 0; i < acceptedFiles.length; i++) {
      	let file = acceptedFiles[i];
        if (allowed.indexOf(file.type) > -1) {
        	files.push(file);
        }
      }
      props.handleFileData(files);
      setWarningMessage('');
	    setErrors('');
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    noClick: true, // Disable direct click since we handle folder dropping
    noKeyboard: true, // Disable keyboard triggers
  });

  const handleConvertExcel = () => {
    if (file) {
      const reader = new FileReader();
      reader.onload = function (e) {
        let data = e.target.result;
        let readtype = {type: 'array', dense: true, WTF:1 };
        let readData = xlsx.read(data, readtype);
        const wsname = readData.SheetNames[0];
        const ws = readData.Sheets[wsname];
        /* Convert array to json*/
        const parsedData = xlsx.utils.sheet_to_json(ws, {header:1});
        props.handleFileData(parsedData);
        setFile(null);
      };
      reader.readAsArrayBuffer(file);
    } else {
      alert('Please drop a file first!');
    }
  };

  const message = ((props.type === "pdf" || props.type === "cover" || props.type === "epub") && props.pdfFiles.length > 1) ?
    `${props.pdfFiles.length} files staged (files will only be converted if matching metadata is found)` : 
    ((props.type === "pdf" || props.type === "cover" || props.type === "epub") && props.pdfFiles.length === 1) ? 
    `${props.pdfFiles[0].path}` : 
    (props.type === "xlsx" && props.data) ? 
    `${props.data.length} book records staged (each record will only be processed if matching PDF files are found)` : 
    props.label;

  const handleRemove = () => {
  	if (props.type === "pdf" || props.type === "cover" || props.type === "epub") {
  		props.handleFileData([]);
  	} else if (props.type ==="xlsx") {
  		setFile(null);
  		props.handleFileData(null);
  	}
  }

  let removeButton = "";

  if (props.data || (props.pdfFiles && props.pdfFiles.length > 1)) {
  	removeButton = (<Button 
  		intent="danger"
			onClick={(e) => {
				handleRemove();
			}}
		>
			Remove file(s)
		</Button>);
  }

  let compactRemoveButton = "";

  if (
  	(props.compact && props.data) || 
  	(props.compact && props.pdfFiles && props.pdfFiles.length === 1)
  ) {
  	compactRemoveButton = (<IconButton 
  	  icon={CrossIcon}
  		intent="danger"
			onClick={(e) => {
				handleRemove();
			}}
			marginBottom={8}
			marginLeft={8}
		/>
		);
  }

    return (
    	<Pane
    		display="flex"
    		flexDirection={props.compact ? "row" : "column"}
	    	justifyContent="flex-start"
	    	alignItems="center"
	    	marginRight={24}
    	>
	    	<Pane
	    		{...getRootProps()}
	    		className="dropzone"
	    		height={props.compact ? 48 : 240}
	    		width={props.compact ? 240 : 160}
	    		border="default"
	    		borderStyle="dashed"
	    		backgroundColor="white"
	    		display="flex"
	    		justifyContent="center"
	    		alignItems="center"
	    		padding={12}
	    		marginBottom={8}
	    	>
	    	  <input {...getInputProps()} type="file" />
	    		<Paragraph 
	    			className="dropzoneText" 
	    			textAlign="center" 
	    			wordBreak={(props.pdfFiles && props.pdfFiles.length === 1) ? "break-all" : "normal"}
	    		>
	    		 {message}
	    		</Paragraph>
	    	</Pane>
	    	{removeButton}
	    	{compactRemoveButton}
	    </Pane>
    )
}

export default DropFile;
