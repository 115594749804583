import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import { AuthProvider } from './context/AuthContext';
import { StatusProvider } from './context/StatusContext';

import './App.css';

import HomePage from './pages/HomePage';
import LoginPage from './pages/LoginPage';

import PrivateRoute from './utils/PrivateRoute';

function App() {
    return (
        <div className="App" height="100vh">
            <Router>
              <AuthProvider>
                <StatusProvider>
                  <Routes>
                      <Route path="/app" element={<PrivateRoute><HomePage/></PrivateRoute>} />
                      <Route path="/" element={<LoginPage/>}/>
                  </Routes>
                </StatusProvider>
              </AuthProvider>
            </Router>
        </div>
    );
}

export default App;

// To Do:
// Get server to serve django app - 1
// Lambda can hit API (api key, etc...) - 0.5
// Replacement pages: lambda - 0.5
// Lambda: uses conversion settings (I think this is done?) - 0.25

// Save dropbox path to DB -- not actually possible via the Saver...

// Manual upload: GO does not reset conversion settings...
// Upload: conversion settings wonkiness?

// "Download all selected..." => Actually download the selected files
