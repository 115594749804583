import React, { useEffect, useState } from 'react';
import { Pane, Paragraph, Button, FullCircleIcon, CrossIcon, TickCircleIcon } from 'evergreen-ui';

const Status = (props) => {
  const [items, setItems] = useState([]);

  useEffect(() => {
    let tmp = props.items.filter((item) => item.hasOwnProperty('eisbn'));
    setItems(tmp);
  }, [props.items]);

  return (
  	<Pane padding={12}>
      {props.items.length > 0 && (
        <Pane display="flex" flexDirection="row">
          <Paragraph marginRight={8}>Converting {items.length} books:</Paragraph>
          <Button size="small" appearance="primary" onClick={e => props.handleClearStatus()}>Clear List</Button>
        </Pane>
      )}
      {items.map((item) => {
        if (item.eisbn) {
          return <StatusItem key={`item${item.conversion_id}${item.eisbn}`} item={item} />;
        }
      })}
    </Pane>
  )
}

const StatusItem = (props) => {
  return (
    <Pane>
      <Paragraph size="small">
        {props.item.status === "SUCCESS" ? (
          <TickCircleIcon color="success" size={8} marginRight={8} />
        ) : props.item.status === "FAIL" ? (
          <CrossIcon color="danger" size={12} marginRight={8} />
        ) : (
          <FullCircleIcon color="warning" size={8} marginRight={8} />
        )}
        {props.item.title_long} ({props.item.eisbn}) {props.item.message ? `- ${props.item.message}` : ''}
      </Paragraph>
    </Pane>
  )
}

export default Status;