import React, { useState } from 'react';
import { Pane, Paragraph, Dialog, Button, TextInput, Checkbox, PlusIcon, InfoSignIcon } from 'evergreen-ui';
import ExpandablePane from "./ExpandablePane";
import DropFile from "./DropFile";
import Note from "./Note";
import ReplacePage from "./ReplacePage";
import InsertPage from "./InsertPage";
import MetadataForm from "./MetadataForm";
import CustomPages from "./CustomPages";
import env from "react-dotenv";

const EditEpub = (props) => {
  const { requestWithAuth } = props;

    return (
      <ExpandablePane 
        expanded={false} 
        label="Edit EPUB" 
        content={<EditEpubInner handleSetStatus={props.handleSetStatus} handleTriggerConversion={props.handleTriggerConversion} requestWithAuth={requestWithAuth} />} 
      />
    )
}

const EditEpubInner = (props) => {
  const { requestWithAuth } = props;
  const [showNote, setShowNote] = useState(false);
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [interior, setInterior] = useState([]);
  const [note, setNote] = useState("");
  const [replacements, setReplacements] = useState([]);
  const [showReplacements, setShowReplacements] = useState(false);
  const [metadata, setMetadata] = useState({eisbn: ""});
  const [shouldUpdateMetadata, setShouldUpdateMetadata] = useState(false);

  const updateMetadata = (updated) => {
    if (updated.hasOwnProperty("eisbn")) {
      setError("");
    }
    setMetadata({...metadata, ...updated});
  }

  const updateReplacements = (updated) => {
    setReplacements([...replacements, updated]);
  }

  const removeReplacement = (number) => {
    let updated = replacements.filter(r => r.page !== number);
    setReplacements(updated);
  }

  const runConversion = async () => {
    // create book record (validating for existence first)
    const res = await requestWithAuth("post", `${env.BASE_URL}/api/books/none/validate_and_create_book/`, metadata);
    if (res && res.data) {
      if (res.data.status === "fail") {
        setError(res.data.message);
      } else {
        props.handleSetStatus({
          conversion_id: "epub001", 
          status: "UPLOADING...",
          eisbn: metadata.eisbn,
          title_long: "EPUB Edit",
          message: "Uploading files..."
        });
      }
    }
    // create item object
    let item = {...metadata};
    if (interior.length > 0) {
      item["interior"] = interior[0].path;
      item["interior_file"] = interior[0];
    }
    if (replacements.length > 0) {
      item["replacements"] = replacements;
    }
    // trigger conversion
    if (item.hasOwnProperty("interior")) {
      props.handleTriggerConversion([item, note, {edit_epub: true}, "epub001"]);
    }
    // reset fields
    setMetadata({eisbn: ""});
    setShouldUpdateMetadata(false);
    setInterior([]);
    setReplacements([]);
    setNote("");
  }

  let howmany = replacements.length > 0 ? "another" : "a";

  // page reference should be according to reading direction of book
  // -- the first page the user sees in their app is page 1, regardless of reading direction

  return (
    <Pane
      display="flex"
      flexDirection="row"
      justifyContent="space-around"
      alignItems="flex-start"
      background="tint1"
      padding={12}
    >
      <Pane marginRight={16}>
        <DropFile label="EPUB file..." type="epub" handleFileData={setInterior} pdfFiles={interior} compact={true} />
        <Pane display="flex" flexDirection="row" alignItems="center" marginBottom={12}>
          <InfoSignIcon color="info" marginRight={8} />
          <Paragraph size={300} color="blue500" onClick={e => setShowNote(true)}>A note about page order</Paragraph>
        </Pane>
        <Pane>
          <Dialog
            isShown={showNote}
            title="A Note About Page Order"
            confirmLabel="Got it!"
            width={560}
            hasCancel={false}
            onConfirm={(close) => {
              setShowNote(false);
              close();
            }}
          >
            <Paragraph>
              When adding a replacement page, remember that the page number to replace is   
              determined based on reading order, and NOT based on reading direction. That is to say, 
              the first page that a user sees when they open a book in their eBook Reader would be 
              page 1 (including the cover). This will be the same page regardless of whether  
              the book is set to RTL or LTR reading direction.
            </Paragraph>
          </Dialog>
        </Pane>
        {replacements.map(r => {
          if (r.mode === "replace") {
            return <ReplacePage data={r} handleRemoveReplacement={removeReplacement} key={r.page} />
          } else {
            return <InsertPage data={r} handleRemoveReplacement={removeReplacement} key={r.page} />
          }
        })}
        <Button onClick={e => setShowReplacements(true)}><PlusIcon marginRight={12} /> Add {howmany} custom page...</Button>
        <CustomPages editEpub={true} handleSetShown={setShowReplacements} show={showReplacements} handleAddCustomPage={updateReplacements} />
      </Pane>
      <Pane>
        {error && (<Paragraph maxWidth="340px" size={300} color="red">{error}</Paragraph>)}
        <Pane marginBottom={12}>
          <TextInput 
            isInvalid={metadata.eisbn.length > 13}
            placeholder="eISBN (REQUIRED)"
            value={metadata.eisbn} 
            onChange={e => {
              updateMetadata({eisbn: e.target.value});
            }}
          />
          <Paragraph size={300} width="75%">
            The updated EPUB will be available in the Books & Downloads tab; 
            look for a Book record with this ISBN.
          </Paragraph>
        </Pane>
        <Note value={note} handleChange={setNote} handleRemoveNote={e => setNote("")} />
        <Button disabled={interior.length === 0 || !metadata.eisbn} onClick={e => runConversion()} marginTop={12} appearance="primary" intent="success" size="large">Go</Button>
      </Pane>
    </Pane>
  )
}

export default EditEpub;

// <Pane>
//   <Checkbox 
//     label="Update metadata?" 
//     checked={shouldUpdateMetadata} 
//     onChange={e => setShouldUpdateMetadata(e.target.checked)}
//   />
// </Pane>
