import React, { Component } from "react";
import { Pane, Paragraph } from 'evergreen-ui';
import SearchBar from "./SearchBar";
import logo from '../kup-logo-lowres.png';

const NotificationsBar = (props) => {
  return (
    <Pane 
      display="flex" 
      flexDirection="row" 
      alignItems="center" 
      justifyContent="space-between" 
      height={48} 
      width="100%"
      padding={12}
      background="orange500"
    >
      <Paragraph color="white">ERROR: {props.error}</Paragraph>
    </Pane>
  );
}

export default NotificationsBar;