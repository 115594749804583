import { createContext, useState, useEffect } from 'react';
import env from "react-dotenv";

const StatusContext = createContext();

export default StatusContext;

export const StatusProvider = ({children}) => {
  const [status, setStatus] = useState([]);
  const [update, setUpdate] = useState({});

  const handleSetStatus = (item) => {
    setStatus(status => [...status, item]);
  }

  const handleClearStatus = (item) => {
    setStatus([]);
  }

  const handleSetUpdate = (item) => {
    setUpdate(item);
  }

  // 9781234567890

  const updateStatusItemByEisbn = (eisbn, updates) => {
    let revised_arr = [...status];
    for (let i=0; i<status.length; i++) {
        if (status[i].eisbn === eisbn) {
            let updated_item = status[i];
            for (let k in updates) {
                updated_item[k] = updates[k];
            }
            revised_arr[i] = updated_item;
        }
    }
    setStatus(status => revised_arr);
  }

  const updateStatusItem = (arr, item, ix, updates) => {
    let revised_arr = [...arr];
    let updated_item = item;
    for (let k in updates) {
        updated_item[k] = updates[k];
    }
    revised_arr[ix] = updated_item;
    return [revised_arr, updated_item];
  }

  useEffect(() => {
    if (update.hasOwnProperty('eisbn')) {
      updateStatusItemByEisbn(update.eisbn, update.updates);
      setUpdate({});
    }
  }, [update]);

  return(
    <StatusContext.Provider value={{ status, handleSetStatus, handleClearStatus, handleSetUpdate, updateStatusItemByEisbn, updateStatusItem }}>
      {children}
    </StatusContext.Provider>
  )
}