import React, { useEffect, useState } from "react";
import { Pane, Paragraph, Pagination, Checkbox, Text } from 'evergreen-ui';
import Book from "./Book";
import Download from "./Download";
import ReportDownload from "./ReportDownload";
import env from "react-dotenv";

const Books = (props) => {
  const { requestWithAuth } = props;
  const [load, setLoad] = useState(true);
  const [mode, setMode] = useState("books");
  const [count, setCount] = useState(0);
  const [books, setBooks] = useState([]);
  const [page, setPage] = useState(1);
  const [allEpub, setAllEpub] = useState(false);
  const [allPdf, setAllPdf] = useState(false);
  const [allCover, setAllCover] = useState(false);
  const [selected, setSelected] = useState({});
  const [downloads, setDownloads] = useState([]);

  const setDownloadAllOfType = (allOfType, myType, conversionKey) => {
    if (!allOfType) {
      let newhash = {...selected};
      let newarr = [];
      for (let i=0; i<books.length; i++) {
        if (
          books[i].conversions && 
          books[i].conversions.length > 0 && 
          books[i].conversions[0][conversionKey]
        ) {
          newhash[books[i].eisbn][myType] = true;
          if (downloads.indexOf(books[i].conversions[0][conversionKey]) < 0) {
            newarr.push(books[i].conversions[0][conversionKey]);
          }
        }
      }
      setSelected(newhash);
      setDownloads([...downloads, ...newarr]);
    } else {
      let newhash = {...selected};
      let newarr = [...downloads];
      for (let i=0; i<books.length; i++) {
        newhash[books[i].eisbn][myType] = false;
        if (newarr.indexOf(books[i].conversions[0][conversionKey]) > -1) {
          let index = newarr.indexOf(books[i].conversions[0][conversionKey]); 
          newarr.splice(index, 1);
        }
      }
      setSelected(newhash);
      setDownloads(newarr);
    }
  }

  const handleSetDownloadAll = (myType) => {
    if (myType === "epub") {
      setDownloadAllOfType(allEpub, "epub", "epub_download");
      setAllEpub(!allEpub);
    }
    if (myType === "pdf") {
      setDownloadAllOfType(allPdf, "pdf", "pdf_download");
      setAllPdf(!allPdf);
    }
    if (myType === "cover") {
      setDownloadAllOfType(allCover, "cover", "cover_download");
      setAllCover(!allCover);
    }
  }

  const handleSetDownloadSingle = (eisbn, myType) => {
    let newhash = {...selected};
    newhash[eisbn][myType] = !selected[eisbn][myType];
    let conversionKey = myType+"_download";
    let newarr = [...downloads];
    if (selected[eisbn][myType] === true) {
      // we're adding the download
      let book = books.filter(b => b.eisbn === eisbn)[0];
      if (downloads.indexOf(book.conversions[0][conversionKey]) < 0) {
        // find the matching book record
        newarr.push(book.conversions[0][conversionKey]);
      }
    } else {
      // we're removing the download
      let book = books.filter(b => b.eisbn === eisbn)[0];
      if (downloads.indexOf(book.conversions[0][conversionKey]) > -1) {
        let index = newarr.indexOf(book.conversions[0][conversionKey]); 
        newarr.splice(index, 1);
      }
    }
    setSelected(newhash);
    setDownloads(newarr);
  }

  const getBookCount = async () => {
    let queryString = "";
    if (props.query && props.query !== "") {
      queryString = `?q=${props.query}`;
      props.handleResetSearch();
    }
    const res = await requestWithAuth("post", `${env.BASE_URL}/api/books/none/count/${queryString}`);
    return await res;
  }

  const getBooks = async (num, filters) => {
    let queryString = "";
    if (props.query && props.query !== "") {
      queryString = `&q=${props.query}`;
      props.handleResetSearch();
    }
    let page = "";
    if (props.mode === "books") {
      page = `page=${num}`;
    }
    // https://api.example.org/accounts/?page=4
    const res = await requestWithAuth("get", `${env.BASE_URL}/api/books/?${page}${queryString}`, filters);
    return await res;
  }

  const getBookConversions = async (book_id) => {
    const res = await requestWithAuth("post", `${env.BASE_URL}/api/conversions/none/get_book_conversions/`, {book: book_id});
    return await res;
  }

  const handleReload = () => {
    setLoad(true);
  }

  useEffect(() => {
    if (props.load === true) {
      setLoad(props.load);
    }
  }, [props.load]);

  useEffect(() => {
    if (props.mode && props.mode != mode) {
      setMode(props.mode);
    }
  }, [props.mode]);

  useEffect(() => {
    if (load === true) {
      async function fetchCount() {
        const res = await getBookCount();
        if (res && res.status === 200) {
          setCount(res.data.count);
          props.handleSetSearchCount(res.data.count);
          const book_res = await getBooks(1, {});
          if (book_res && book_res.status === 200) {
            setBooks(book_res.data.results);
            let newhash = {};
            for (let i=0; i<book_res.data.results.length; i++) {
              let book = book_res.data.results[i];
              newhash[book.eisbn] = {epub: false, pdf: false, cover: false};
            }
            setSelected(newhash);
          }
        }
      }
      fetchCount();
      setLoad(false);
      props.handleLoadBooks(false);
    }
  }, [count, books, load]);

  const setPageAndLoadBooks = async (num) => {
    setPage(num);
    const book_res = await getBooks(num, {});
    if (book_res && book_res.status === 200) {
      setBooks(book_res.data.results);
    }
  }

  return (
    <Pane 
      display="flex" 
      flexDirection="column" 
      alignItems="flex-start" 
      justifyContent="flex-start" 
      padding={12}
    >
      <Pane display="flex" flexDirection="row" justifyContent="space-between" alignItems="center" width="100%">
        <MultiSelect allEpub={allEpub} allPdf={allPdf} allCover={allCover} handleSetDownloadAll={handleSetDownloadAll} downloads={downloads} requestWithAuth={requestWithAuth} />
        {mode === "report" && (
        <ReportDownload books={books} />
        )}
      </Pane>
      <Pane width="100%" borderBottom="default" marginBottom={12} />
      {books.map(book => <Book key={book.eisbn} data={book} selected={selected} handleSetDownloadSingle={handleSetDownloadSingle} handleReload={handleReload} handleTriggerConversion={props.handleTriggerConversion} handleSetStatus={props.handleSetStatus} user={props.user} currentUser={props.currentUser} requestWithAuth={requestWithAuth} />)}
      {mode === "books" && (
        <Pane width="100%" display="flex" flexDirection="column" alignItems="center">
          <Pagination page={page} totalPages={Math.ceil(count/20)} onPageChange={num => setPageAndLoadBooks(num)}></Pagination>
        </Pane>
      )}
    </Pane>
  );
}

// reset highlight

const MultiSelect = (props) => {
  const { requestWithAuth } = props;
  const [showdownloads, setShowdownloads] = useState(false);
  const [downloading, setDownloading] = useState(false);

  return (
    <Pane>
      <Pane display="flex" flexDirection="row" alignItems="flex-end">
        <Pane display="flex" flexDirection="column" justifyContent="center" alignItems="center" width={40}>
          <Text size={300}>EPUB</Text>
          <Checkbox 
            checked={props.allEpub} 
            onChange={e => props.handleSetDownloadAll("epub")} 
          />
        </Pane>
        <Pane display="flex" flexDirection="column" justifyContent="center" alignItems="center" width={40}>
          <Text size={300}>PDF</Text>
          <Checkbox 
            checked={props.allPdf} 
            onChange={e => props.handleSetDownloadAll("pdf")} 
          />
        </Pane>
        <Pane display="flex" flexDirection="column" justifyContent="center" alignItems="center" width={40}>
          <Text size={300}>Cover</Text>
          <Checkbox 
            checked={props.allCover} 
            onChange={e => props.handleSetDownloadAll("cover")} 
          />
        </Pane>
        <Paragraph 
          marginBottom={16} 
          size={300} 
          color="blue500"
          onClick={e => setShowdownloads(true)}
        >
          Download all Selected...
        </Paragraph>
      </Pane>
      <Download show={showdownloads} handleSetShown={setShowdownloads} handleSetDownloading={setDownloading} files={props.downloads} mode={"local"} requestWithAuth={requestWithAuth} />
    </Pane>
  )
}

export default Books;